import Cookies from "js-cookie";
import axios from "utils/axios";

export const checkout = async (products, coupons, country, paymentMethod, language, cart_note) => {
    let token = Cookies.get('access_token');
    let obj = {
        products,
        coupons,
        country,
        paymentMethod,
        language,
        cart_note
    }
    try{
        const response = await axios.post(`shop/checkout/`,obj,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`    
            }
        },);
        return response.data;
    }catch(e){
        let errorData = e.response.data;
        throw new Error(JSON.stringify({
            message: errorData.message,
            status: e.response.status, 
            test: errorData.test 
          }));
    }
};

export const purchaseStatus = async () => {
    let token = Cookies.get('access_token');
    try{
        const response = await axios('shop/purchase_status',{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`    
            }
        });
        if(response.data.success){
            return response.data;
        }
        throw new Error('Pending Payment')
    }catch(e){
        throw new Error('Pending Payment');
    }
};