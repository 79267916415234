import { useMutation, useQuery } from "@tanstack/react-query";
import { getShipping, updateShipping } from "apis/shipping";


export const useShipping = () => {
    const { data, isLoading, isError,error, refetch } = useQuery({
      queryKey: ["shipping"],
      queryFn: () => getShipping(),
      staleTime: 1000 * 60
    },);
    return { shipping: isError || isLoading? [] : data.shipping,
            userShippingInformation: isError || isLoading? {} : data.userShippingInformation,
            isLoading, isError,error, refetch}
};
export const useUpdateShipping = () => {
  const { data, error, isError, isPending, mutateAsync } = useMutation({
    mutationKey: ["update_shipping"],
    mutationFn: ({shippingInfo}) => updateShipping(shippingInfo),
  });
  return { isPending, error, isError, postShipping: mutateAsync}
};
  



